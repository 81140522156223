<template>
  <v-dialog
    v-model="updateMatchModal"
    width="800px"
  >
    <v-card class="py-5 pb-5" v-if="matchData">
      <v-card-title>
        <div class="text-center text-h5" style="width: 100%">
          Editar Partida
        </div>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          class="mx-5 mt-5"
          @submit.prevent="updatedMatch()"
        >
          <v-autocomplete
            item-text="name"
            item-value="_id"
            label="Time A *"
            hint="Digite o nome do time"
            no-data-text="Sem dados para listagem."
            class="mb-2"
            v-model="match.teamA"
            :items="teams"
            :loading="loadingTeams"
            hide-no-data
            outlined
            persistent-hint
            :rules="[rules.required]"
          />

          <v-autocomplete
            item-text="name"
            item-value="_id"
            label="Time B *"
            hint="Digite o nome do time"
            no-data-text="Sem dados para listagem."
            class="mb-2"
            v-model="match.teamB"
            :items="teams"
            :loading="loadingTeams"
            hide-no-data
            outlined
            persistent-hint
            :rules="[rules.required, rules.differentValues]"
          />

          <v-datetime-picker
            v-model="match.date"
            :datePickerProps="datePickerProps"
            :textFieldProps="textFieldProps"
            :timePickerProps="timeOpts"
            clearText="limpar"
            label="Data da partida *"
            date-format="dd/MM/yyyy"
          >
            <template slot="dateIcon">
              <v-icon>mdi-calendar</v-icon>
            </template>
            <template slot="timeIcon">
              <v-icon>mdi-clock-outline</v-icon>
            </template>
          </v-datetime-picker>

          <v-select
            v-model="match.status"
            outlined
            label="Status da partida"
            :items="matchStatuses"
            item-text="text"
            item-value="value"
            :rules="[rules.required]"
          />

          <v-text-field
            v-model="match.goalsA"
            outlined
            required
            persistent-hint
            class="mb-2"
            type="number"
            label="Gols feitos pelo Time A"
          />

          <v-text-field
            v-model="match.goalsB"
            outlined
            required
            persistent-hint
            class="mb-2"
            type="number"
            label="Gols feitos pelo Time B"
          />
        </v-form>
      </v-card-text>

      <v-card-actions class="mt-5 mx-5">
        <v-row justify="center">
          <v-btn
            class="py-4"
            color="primary"
            type="submit"
            :disabled="saving"
            @click="updatedMatch()"
          >
            <v-progress-circular
              v-if="saving"
              indeterminate
              size="25"
              width="3"
              class="px-6"
              color="white"
            />
            <span
              v-else
              class="px-6"
            >
              Salvar
            </span>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    matchData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      saving: false,
      loadedTeams: false,
      loadingTeams: false,
      valid: true,
      match: {
        teamA: null,
        teamB: null,
        status: null,
        goalsA: 0,
        goalsB: 0,
        date: null
      },
      matchStatuses: [
        {
          text: 'Esperando início',
          value: 'waiting'
        },
        {
          text: 'Iniciada',
          value: 'started'
        },
        {
          text: 'Finalizada',
          value: 'finished'
        },
        {
          text: 'Partida cancelada',
          value: 'canceled'
        }
      ],
      teams: [],
      datePickerProps: {
        'no-title': true,
        scrollable: true,
        locale: 'pt-br'
      },
      textFieldProps: {
        readonly: true,
        outlined: true
      },
      timeOpts: {
        format: '24hr'
      },
      rules: {
        required: value => !!value || 'Este campo é obrigatório.',
        differentValues: value => value !== this.match.teamA || 'Você não pode selecionar o mesmo time.'
      }
    }
  },
  computed: {
    updateMatchModal: {
      get () {
        return this.$store.state.updateMatchModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'updateMatchModal',
          value: val
        })
      }
    }
  },
  watch: {
    updateMatchModal (val) {
      if (val && !this.loadedTeams) {
        this.getTeams()
      }
      if (!val) {
        this.match = {
          teamA: null,
          teamB: null,
          status: null,
          goalsA: 0,
          goalsB: 0,
          date: null
        }
      } else {
        this.match = {
          ...this.matchData,
          teamA: this.matchData.teamA._id,
          teamB: this.matchData.teamB._id
        }
        this.match.date = moment(this.match.date)._d
      }
    }
  },
  methods: {
    updatedMatch () {
      if (this.$refs.form.validate()) {
        if (!this.match.date) {
          this.$toast.warning('Informe a data e hora de início da partida.')
          return
        }

        this.saving = true
        const championshipId = this.$store.state.selectedChampionship
        const roundId = this.$store.state.selectedRound
        const matchId = this.match._id

        if (!matchId) {
          this.updateMatchModal = false
          return
        }
        this.$http.put(`/championships/${championshipId}/rounds/${roundId}/matches/${matchId}`, {
          ...this.match,
          _id: undefined
        })
          .then(() => {
            this.saving = false
            this.updateMatchModal = false
            this.$emit('updated')
            this.$toast.success('Cadastro atualizado com sucesso.')
          })
          .catch((err) => {
            this.saving = false
            this.$toast.error(err.response.data.message)
          })
      }
    },
    getTeams () {
      this.loadingTeams = true
      const championshipId = this.$store.state.selectedChampionship

      this.$http.get(`/championships/${championshipId}/teams`)
        .then((res) => {
          this.teams = res.data.data
        })
        .catch((err) => {
          this.$toast.error(err.response.data.message)
        })
        .finally(() => {
          this.loadedTeams = true
          this.loadingTeams = false
        })
    }
  }
}
</script>
